import React, { Component } from "react";
import topclass from "../../resources/img/topclass.png";
import launch from "../../resources/img/launch.png";
import launch1 from "../../resources/img/launch1.png";

class Banner extends Component {
 
  render() {
    return (
      <div >

    <div className="container-fluid padding d-none d-sm-none d-md-block d-lg-block d-xl-block" style={{marginTop:50}}>
    <div className="row text-center">
        <div className="col-sm-12 col-md-6 col-lg-6" >
            <div  id="topclass">
                <img  src={topclass} className="img-fluid" alt="img"/>                 
            </div>            
        </div>   
        <hr/>
        <div className="col-sm-12 col-md-6 col-lg-6" > 
            <div className="row">
                <div className="col-md-7 col-lg-7 r2bg">
                    <img src={launch} className="img-fluid" alt="img"/>
                </div>
                <div className="col-md-5 col-lg-5 r3bg"> 
                </div>
            </div>           
        </div> 
    </div>    
</div>  

 {/* Image sec for mobile  */}

<div className="container-fluid padding  d-md-none d-lg-none d-xl-none">
    <div className="row text-center">
        <div className="col-sm-12 col-md-6 col-lg-6" >
            <div  id="topclass">
            <img  src={topclass} className="img-fluid" alt="img"/>                        
            </div>            
        </div>           
        <div className="col-sm-12 col-md-6 col-lg-6" > 
            <div className="row">
                <div className="col-md-12 col-lg-12">
                <img src={launch1} className="img-fluid" alt="img"/>
                </div>
                
            </div>           
        </div> 
    </div>    
</div> 
     
      </div>
    );
  }
}

export default Banner;
