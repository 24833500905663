import React, { Component } from 'react'
//import Albums from "./Albums";
//import Albums from "./OwlAlbum";
import Albums from './Carouselalbum'

class index extends Component {
  render() {
    return (
      <div
        style={{
          marginTop: 80,
          backgroundColor: 'hsla(0,0%,85.7%,.1)',
          paddingTop: '5rem',
          paddingBottom: '5rem',
        }}
      >
        <Albums />
      </div>
    )
  }
}

export default index
