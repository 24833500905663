import React, { Component } from "react";
import Talkwithus from "./Talkwithus";


class index extends Component {
  render() {
    return (
      <Talkwithus />
    );
  }
}

export default index;
