import React, { Component } from 'react';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


import client1 from "../../resources/img/clientlogos/pugalilogo.jpg";
import client2 from "../../resources/img/clientlogos/kfoundation.png";
import client3 from "../../resources/img/clientlogos/fceslogo.jpg";
import client4 from "../../resources/img/clientlogos/fstrustlogo.jpg";
import client5 from "../../resources/img/clientlogos/aadhalogo.jpg";
import client6 from "../../resources/img/clientlogos/sandglogo.png";
import client7 from "../../resources/img/clientlogos/jrretlogo.png";
import client8 from "../../resources/img/clientlogos/sameeplogo.jpg";


const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

class Carouselalbum extends Component {

    render() {
        
        return (                
            <div className="container-fluid padding">
            <div className="row text-center">
                    <div className="col-md-12 col-sm-12">
                    {/* <img  src={album} className="img-fluid"/> */}
                    <h1 className="dis-5">Our happy customers</h1>
             </div>
            </div>
            <br/>
            <br/>
            <div className="row ">
                  <div className="col-xs-1 col-sm-2 col-md-2 col-lg-2"></div>
                  <div className="col-xs-10 col-sm-8 col-md-8 col-lg-8">


                   <Carousel
                   responsive={responsive}
                   >
                    <div className="item">  <img style={logoStyle} src={client1} className="img-fluid" alt="client"/></div>
                    <div className="item">  <img style={{height:'8rem'}}  src={client2} className="img-fluid" alt="client"/></div>
                    <div className="item">  <img style={logoStyle} src={client3} className="img-fluid" alt="client"/></div>
                    <div className="item">  <img style={logoStyle} src={client4} className="img-fluid" alt="client"/></div>       
                    <div className="item">  <img style={logoStyle} src={client5} className="img-fluid" alt="client"/></div>       
                    <div className="item">  <img style={logoStyle} src={client6} className="img-fluid" alt="client"/></div>       
                    <div className="item">  <img style={logoStyle} src={client7} className="img-fluid" alt="client"/></div>       
                    <div className="item">  <img style={logoStyle} src={client8} className="img-fluid" alt="client"/></div>             
                 </Carousel> 

                  </div>
                  <div className="col-xs-1 col-sm-2 col-md-2 col-lg-2"></div>
            </div>
       
        </div>

        );
    }
}

const logoStyle = {
    height: '10rem',
      
  };

export default Carouselalbum;