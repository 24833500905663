import React from "react";
import "./resources/styles.css";
import { Switch, Route } from "react-router-dom";

import Home from "./components/Home";
import Adminlogin from "./components/Admin/Adminlogin";
import Admindashboard from "./components/Admin/Admindashboard";
import { ProtectedRoute } from "./protected.route";

const Routes = () => {
  return (
  
      <Switch>        
        <Route path="/admin" exact component={Adminlogin} />
        <ProtectedRoute path="/admindashboard" exact component={Admindashboard} />
        <Route path="/" exact component={Home} />
      </Switch>
 
  );
};

export default Routes;
