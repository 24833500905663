import React, { Component } from 'react'

import logo from '../../resources/img/logo.png'
import f from '../../resources/img/f.png'
import t from '../../resources/img/t.png'
import n from '../../resources/img/in.png'

class Footer extends Component {
  render() {
    return (
      <div>
        {/* <hr className='my-4' /> */}
        {/* <!--- Footer --> */}
        <footer>
          <div
            className='container-fluid padding'
            style={{ backgroundColor: 'hsla(0,0%,85.7%,.1)' }}
          >
            <div className='row'>
              <div className='col-md-1'></div>
              <div className='col-md-12 col-lg-10 padlr-5'>
                <div className='row'>
                  <div className='col-md-6 col-lg-4 '>
                    <img style={{ width: 100 + '%' }} src={logo} alt='img' />
                    <br />
                    <br />
                    <div className='padding' style={{ marginLeft: 3 + 'rem' }}>
                      <img
                        style={{ width: 15 + '%', margin: '5px' }}
                        src={f}
                        alt='img'
                      />
                      <img
                        style={{ width: 14 + '%', margin: '5px' }}
                        src={t}
                        alt='img'
                      />
                      <img
                        style={{ width: 14 + '%', margin: '5px' }}
                        src={n}
                        alt='img'
                      />
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-2'>
                    <h6>About Us</h6>
                    <br />
                    <p>Services</p>
                    <p>Our works</p>
                    <p>Estimate</p>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <h6>Services</h6>
                    <br />
                    <p>Domain & Hosting</p>
                    <p>UI/UX & Web design</p>
                    <p>Software Development</p>
                    <p>Ecommerce Development</p>
                    <p>Logo Design</p>
                    <p>SEO</p>
                    <p>Bulk SMS & E-Mail</p>
                    <p>Android & ios development</p>
                  </div>
                  <div className='col-md-6 col-lg-3'>
                    <h6>Get in touch</h6>
                    <br />
                    <p>1/9, 5th Cross</p>
                    <p>Sengunthapuram Main Road</p>
                    <p>Karur - 639 002</p>
                    <br />

                    <p style={{ color: '#000005' }}>+91 79044 83853</p>
                    <p>hello@arttsoft.com</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-1'></div>
            </div>
          </div>
        </footer>
        {/* <hr className='my-4' /> */}

        <div className='goto-top2' id='div1'>
          <p className='goto-top' style={{ fontSize: '3em', color: '#E73A5E' }}>
            <i className='fa fa-chevron-up' id='scrolltop'></i>
          </p>
        </div>
      </div>
    )
  }
}

export default Footer
