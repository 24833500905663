import React, { Component } from "react";
import Footer from "./Footer";


class index extends Component {
  render() {
    return (
      <Footer />
    );
  }
}

export default index;
