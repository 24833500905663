import React, { Component } from 'react';
import axios from "axios";
import auth from "../../auth";

import {apiUrl} from "../../config";

const apiURL = apiUrl;

class Admindashboard extends Component {

state={
    data :{},
    id:"",
    name:"",
    email:"",
    message:""
}

componentDidMount(){
    this.getAll();
}

handleEdit = (data) =>{
//console.log('--------+++++++++---------', data);
this.setState({
    id:data._id,
    name:data.name,
    email:data.email,
    message:data.message
  })
}

handleDelete = (e) =>{
    this.getAll();
    var option = window.confirm(`Are You sure want to delete ${e.name}`)
    if(option){
        axios.delete(`${apiURL}/api/users/delete/${e._id}`).then(response => {     
         this.getAll();                
       }) 
       .then(this.setState({
        id:"",name:"",email:"",message:""
      })) 
    }    
}

    getAll = () => {
        axios.get(`${apiURL}/api/users/arttdetails`).then(response => {
            console.log(response);
            this.setState({
                data:response.data
              })
          })
    }


    onChange = e => {    
        // console.log('_________________',e.target.name);
        this.setState({
            [e.target.name]:e.target.value
          })
       };
    
       onSubmit = e => {
        e.preventDefault();
 
        const user = { 
            id : this.state.id,
            name : this.state.name,
            email: this.state.email,
            message: this.state.message
        }
 
        //console.log('+-+-+-************-+-+-+',user);
 
        axios.put(`${apiURL}/api/users/update`,user).then(response => {
         //console.log('--------response--------',response);
         this.getAll();                
       })      
       .then(this.setState({
         id:"",name:"",email:"",message:""
       }))        
    } 

    render() {

        //console.log('_____________________',this.state.data);
        return (
            <div>       
              
                    <div className="row" style={{margin:"2rem"}}>
                    
                    <div className="col-md-3" style={{backgroundColor:'#f1f2f6',marginTop:'6rem'}}>
                
                        <form onSubmit={this.onSubmit.bind(this)} >
                            <div className="group">      
                            <input style={{width: 100 + '%'}} type="text"
                            name="name"
                            value={this.state.name}
                            onChange={this.onChange.bind(this)}
                            required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Name</label>
                            </div>
                            <div className="group">      
                          <input style={{width: 100 + '%'}} type="email"
                           name="email"
                           value={this.state.email}                          
                           onChange={this.onChange.bind(this)}
                           required />
                           <span className="highlight"></span>
                           <span className="bar"></span>
                           <label>Email</label>
                        </div>

                        <div className="group">      
                            <input style={{width: 100 + '%'}} type="text" rows="3"
                            name="message"
                            value={this.state.message}
                            onChange={this.onChange.bind(this)}
                            required />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>Message</label>
                        </div>
                        {/* <div className="form-group">                        
                          <input type="text" name="name" className="form-control" value={this.state.name} onChange={this.onChange.bind(this)} placeholder=" Enter Name" />
                        </div> */}
                        {/* <div className="form-group">                        
                          <input type="email" name="email" className="form-control" value={this.state.email} onChange={this.onChange.bind(this)}  placeholder="Enter email" />
                        </div>
                        <div className="form-group">
                          <input type="text" name="message" className="form-control" value={this.state.message} onChange={this.onChange.bind(this)} placeholder="Enter Message" />
                        </div>
                         */}
                        <button type="submit" className="btn btn-block btn-success">Update</button>
                      </form>
                    </div>

                        <div className="col-md-9">    
                             <div className="row">
                                 <div className="col-md-6">
                                 <h2 style={{color:'#28a745'}}>ADMIN DASHBOARD</h2>
                                 </div>
                                 <div className="col-md-6">
                                 <button className="btn btn-outline-danger"
                                    onClick={() => {
                                    auth.logout(() => {
                                        this.props.history.push("/");
                                    });
                                    }}
                                >
                                    Logout
                                </button>
                                 </div>
                             </div>                       
                            
                            <table className="table table-bordered table-hover">
                                <thead style={{backgroundColor:'#fc5c65', color:'white'}}>
                                <tr>
                                    <th>Name</th>                                    
                                    <th>Email</th>
                                    <th>Message</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                                </thead>
                                <tbody style={{fontFamily:'Arial, Helvetica, sans-serif'}}>
                                {
                                    this.state.data.length > 0 ?
                                    (
                                        this.state.data.map(e =>(
                                            <tr key={e._id}>
                                            <td>{e.name}</td>
                                            <td>{e.email}</td>
                                            <td>{e.message}</td>
                                            <td><button className="btn btn-warning" onClick={()=>{
                                                this.handleEdit(e)
                                            }}><i className="fas fa-pencil-alt" style={{color:"#000"}}></i></button></td> 
                                            <td><button className="btn btn-danger" onClick={()=>{
                                                this.handleDelete(e)
                                            }}><i className="far fa-trash-alt"></i></button></td>
                                            </tr> 
                                        ))
                                        
                                    )
                                    :
                                    (
                                        <tr>
                                            <td>No data</td>
                                        </tr>    
                                    )
                                }
                                </tbody>
                            </table>

                        </div>
                        
                    </div>
               </div>

        );
    }
}

export default Admindashboard;