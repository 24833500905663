import React, { Component } from 'react'
import awesomeservices from '../../resources/img/awesomeservices.png'

import s1 from '../../resources/img/services/s1.png'
import s2 from '../../resources/img/services/s2.png'
import s3 from '../../resources/img/services/s3.png'
import s4 from '../../resources/img/services/s4.png'
import s5 from '../../resources/img/services/s5.png'
import s6 from '../../resources/img/services/s6.png'
import s7 from '../../resources/img/services/s7.png'
import s8 from '../../resources/img/services/s8.png'

class Services extends Component {
  render() {
    return (
      <div>
        <div id='services'>
          {/* Services  */}
          <div
            className='container-fluid padding  d-none d-sm-none d-md-block d-lg-block d-xl-block'
            style={{ marginTop: 100, backgroundColor: '#fff' }}
          >
            <div className='row text-center'>
              <div className='col-md-3 col-lg-3'></div>
              <div className='col-md-6 col-lg-6'>
                <img
                  src={awesomeservices}
                  className='img-fluid'
                  alt='service'
                />
              </div>
              <div className='col-md-3 col-lg-3'></div>
            </div>

            <div className='row text-center'>
              <div className='col-md-2'></div>

              <hr />
              <div className='col-md-12 col-sm-12'>
                <div className='row'>
                  <div className='col-md-2 col-sm-2 col-xs-1'></div>
                  <div className='col-md-8 col-sm-8 col-xs-10'>
                    <div className='fixed-bg'>
                      {/* <!-- Row1 --> */}
                      <div className='row' style={{ paddingBottom: 8 }}>
                        <div className='col-md-3 col-sm-12'> </div>
                        <div className='col-md-3 col-sm-12'>
                          <div className='card'>
                            <img className='img-fluid' src={s1} alt='s1' />
                          </div>
                        </div>
                        <div className='col-md-3 col-sm-12'>
                          <div className='card'>
                            <img className='img-fluid' src={s2} alt='s2' />
                          </div>
                        </div>
                        <div className='col-md-3'> </div>
                      </div>

                      {/* <!--Row2 --> */}

                      <div className='row'>
                        <div className='col-md-3 col-sm-12'>
                          <div className='card'>
                            <img className='img-fluid' src={s3} alt='s3' />
                          </div>
                        </div>
                        <div className='col-md-3 col-sm-12'>
                          <div className='card'>
                            <img className='img-fluid' src={s4} alt='s4' />
                          </div>
                        </div>
                        <div className='col-md-3 col-sm-12'>
                          <div className='card'>
                            <img className='img-fluid' src={s5} alt='s5' />
                          </div>
                        </div>
                        <div className='col-md-3 col-sm-12'>
                          <div className='card'>
                            <img className='img-fluid' src={s6} alt='s6' />
                          </div>
                        </div>
                      </div>

                      {/* <!--Row3 --> */}
                      <div className='row'>
                        <div className='col-md-3 col-sm-12'></div>
                        <div className='col-md-3 col-sm-12'>
                          <div className='card'>
                            <img className='img-fluid' src={s7} alt='s7' />
                          </div>
                        </div>
                        <div className='col-md-3 col-sm-12'>
                          <div className='card'>
                            <img className='img-fluid' src={s8} alt='s8' />
                          </div>
                        </div>
                        <div className='col-md-3'> </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2 col-sm-2 col-xs-1'></div>
                </div>
              </div>
            </div>
          </div>

          {/* <!--- Services for mobile --> */}
          <div className='container-fluid padding d-block d-sm-block d-md-none d-lg-none d-xl-none'>
            <div className='row text-center' style={{ marginRight: 15 }}>
              <div className='col-md-12 col-sm-12'>
                <img
                  src={awesomeservices}
                  className='img-fluid'
                  alt='service'
                />
              </div>
              <hr />
              <div className='col-md-12 col-sm-12'>
                <div className='row'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div>
                        <img
                          id='servicesmobile'
                          className='img-fluid'
                          src={s1}
                          alt='s1'
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div>
                        <img
                          id='servicesmobile'
                          className='img-fluid'
                          src={s2}
                          alt='s2'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-12'>
                      <div>
                        <img
                          id='servicesmobile'
                          className='img-fluid'
                          src={s3}
                          alt='s3'
                        />
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <div>
                        <img
                          id='servicesmobile'
                          className='img-fluid'
                          src={s4}
                          alt='s4'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-6'>
                      <div>
                        <img
                          id='servicesmobile'
                          className='img-fluid'
                          src={s5}
                          alt='s5'
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div>
                        <img
                          id='servicesmobile'
                          className='img-fluid'
                          src={s6}
                          alt='s6'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Services
