import React, { Component } from "react";
import logo from "../../resources/img/logo.png";
import logo1 from "../../resources/img/logo1.png";

class Navbar extends Component {
 
  render() {
    return (
      <div>
      <nav className="navbar navbar-expand-md navbar-light navmainheader" style={{backgroundColor:'hsla(0,0%,85.7%,.1)',  boxShadow: "1px 3px 2px #f5f5f5"}}>
      <div className="container-fluid">
          <a href='# ' className="navbar-brand d-none d-sm-none d-md-none d-lg-block d-xl-block"><img src={logo} alt="img"/></a>
          <a href='# ' className="navbar-brand d-block d-sm-block d-md-block d-lg-none d-xl-none"><img src={logo1} alt="img"/></a> 
          <button className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarResponsive">
          <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                      <a href='# ' className="nav-link"> <div className="navlinkcolor" >Home</div></a>
                  </li>
                  <li className="nav-item">
                      <a href="#services" className="nav-link"> <div className="navlinkcolor">Services</div></a>
                  </li>
                  <li className="nav-item">
                      <a href="#aboutus" className="nav-link"> <div className="navlinkcolor">About Us</div></a>
                  </li>
                  <li className="nav-item">
                      <a href="#contactus" className="nav-link"> <div className="navlinkcolor">Contact Us</div></a>
                  </li>  
                  
                 
                   <li className="nav-item">
                       <div > 
                              <a href="#contactus" className="nav-link" id="productbtn">Get Estimate</a>
                       </div>               
                  </li>  
              </ul> 
          </div>
      </div>
      </nav>
      </div>
    );
  }
}

export default Navbar;
