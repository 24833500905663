import React, { Component } from 'react'
import auth from '../../auth'

class Adminlogin extends Component {
  state = {
    name: '',
    password: '',
  }

  handleadmin = () => {
    //this.props.history.push("/Admindashboard");
    if (this.state.name === 'admin' && this.state.password === 'artt') {
      auth.login(() => {
        this.props.history.push('/admindashboard')
      })
    } else {
      alert('Incorrect details')
      this.setState({
        name: '',
        password: '',
      })
    }
  }

  onChange = (e) => {
    // console.log('_________________',e.target.name);
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    return (
      <div>
        {/* <Link to="/">  <button type="button" className="btn btn-outline-info" >Go To Home</button></Link> */}
        {/* <button type="button" class="btn btn-outline-success" onClick={this.handleadmin}>Go To Admin</button>
         */}
        {/* <button className="btn btn-outline-success"
                    onClick={() => {
                    auth.login(() => {
                        this.props.history.push("/admindashboard");
                    });
                    }}
                >
                    Login
                </button> */}

        <div className='container'>
          <h2>Admin Login</h2>
          <form>
            <div className='group'>
              <input
                style={{ width: 100 + '%' }}
                type='text'
                name='name'
                value={this.state.name}
                onChange={this.onChange.bind(this)}
                required
              />
              <span className='highlight'></span>
              <span className='bar'></span>
              <label>Name</label>
            </div>
            <div className='group'>
              <input
                style={{ width: 100 + '%' }}
                type='password'
                name='password'
                value={this.state.password}
                onChange={this.onChange.bind(this)}
                required
              />
              <span className='highlight'></span>
              <span className='bar'></span>
              <label>Password</label>
            </div>
          </form>
          <button
            type='button'
            className='btn btn-block btn-outline-success'
            onClick={this.handleadmin}
          >
            Go To Admin
          </button>
        </div>
      </div>
    )
  }
}

export default Adminlogin
