import React, { Component } from 'react'

import $ from 'jquery'

import Header from './Header'
import Banner from './Banner'
import Services from './Services'
import Aboutus from './Aboutus'
import Technologies from './Technologies'
import Albums from './Albums'
import Talkwithus from './Talkwithus'
import Footer from './Footer'

class Home extends Component {
  componentDidMount() {
    /////////////////////////////////////////////

    // Smooth Scroll
    $(document).ready(function () {
      $('a').on('click', function (event) {
        if (this.hash !== '') {
          event.preventDefault()
          var hash = this.hash
          $('html, body').animate(
            {
              scrollTop: $(hash).offset().top,
            },
            800,
            function () {
              window.location.hash = hash
            }
          )
        }
      })
    })

    //Scroll To Top
    $(document).ready(function () {
      $(window).scroll(function () {
        var top = $('.goto-top')
        if (
          $('body').height() <=
          $(window).height() + $(window).scrollTop() + 200
        ) {
          top.animate({ 'margin-left': '0px' }, 1500)
        } else {
          top.animate({ 'margin-left': '-100%' }, 1500)
        }
      })

      $('.goto-top').on('click', function () {
        $('html, body').animate({ scrollTop: 0 }, 400)
      })

      // Scroll To Top hide in initial
      $('#div1').fadeOut()
      $(window).scroll(function () {
        if ($(window).scrollTop() > 500) {
          $('#div1').fadeIn()
        } else {
          $('#div1').fadeOut()
        }
      })
    })
  }

  render() {
    return (
      <div>
        <Header />
        <Banner />
        <Services />
        <Aboutus />
        <Technologies />
        <Albums />
        <Talkwithus />
        <Footer />
      </div>
    )
  }
}

export default Home
