import React, { Component } from "react";
import Services from "./Services.js";


class index extends Component {
  render() {
    return (
      <Services />
    );
  }
}

export default index;
