import React, { Component } from 'react'
import mongodb from '../../resources/img/technologies/mongodb.png'
import react from '../../resources/img/technologies/react.png'
import nodejs from '../../resources/img/technologies/nodejs.png'
import asp from '../../resources/img/technologies/asp.png'
import expressjs from '../../resources/img/technologies/expressjs.png'
import mysql from '../../resources/img/technologies/mysql.png'

class Technologies extends Component {
  render() {
    return (
      <div>
        {/* <!--- Technologies --> */}
        <div
          className='container-fluid padding d-none d-md-none d-sm-block d-md-block d-lg-block d-xl-block'
          style={{
            marginTop: 70,
            backgroundColor: '#fff',
            paddingTop: 10,
          }}
          id='services'
        >
          <div className='row text-center'>
            <div className='col-md-12'>
              <h1 className='dis-5'>Technologies</h1>
            </div>
          </div>

          <div className='row'>
            <div className='col-xs-3 col-sm-2 col-md-2 col-lg-2'></div>
            <div className='col-xs-6 col-sm-8 col-md-8 col-lg-8'>
              <div className='row text-center '>
                <div className='col-xs-12 col-sm-6 col-md-6 col-lg-2'>
                  <div className='circle'>
                    {' '}
                    <img
                      className='img-fluid '
                      src={mongodb}
                      id='mongodb'
                      alt='mongodb'
                    />{' '}
                  </div>
                </div>

                <div className='col-xs-12 col-sm-6 col-md-6 col-lg-2 '></div>
                <div className='col-sm-6 col-md-6 d-lg-none'></div>

                <div className='col-xs-12 col-sm-6 col-md-6 col-lg-2'>
                  <div className='circle'>
                    {' '}
                    <img
                      className='img-fluid '
                      src={react}
                      id='react'
                      alt='react'
                    />{' '}
                  </div>
                </div>

                <div className=' col-lg-2'></div>

                <div className='col-xs-12 col-sm-6 col-md-6 col-lg-2'>
                  <div className='circle'>
                    {' '}
                    <img
                      className='img-fluid '
                      src={nodejs}
                      id='nodejs'
                      alt='nodejs'
                    />{' '}
                  </div>
                </div>
                <div className='col-xs-12 col-md-0 col-lg-2'></div>
              </div>

              <div className='row text-center '>
                <div className='col-xs-12 col-sm-6 col-lg-2'></div>
                <div className='col-xs-12 col-sm-6 col-lg-2 col-md-6'>
                  <div className='circle'>
                    {' '}
                    <img
                      className='img-fluid '
                      src={asp}
                      id='asp'
                      alt='asp'
                    />{' '}
                  </div>
                </div>

                <div className='col-xs-12 col-sm-0 col-lg-2'></div>

                <div className='col-xs-12 col-lg-2 col-sm-6 col-md-6'>
                  <div className='circle'>
                    {' '}
                    <img
                      className='img-fluid '
                      src={expressjs}
                      id='expressjs'
                      alt='expressjs'
                    />{' '}
                  </div>
                </div>

                <div className='col-xs-12 col-lg-2 col-sm-6 col-md-6'></div>
                <div className='col-sm-6 col-md-6 d-lg-none'></div>

                <div className='col-xs-12 col-sm-6 col-md-6 col-lg-2'>
                  <div className='circle'>
                    {' '}
                    <img
                      className='img-fluid '
                      src={mysql}
                      id='mysql'
                      alt='mysql'
                    />{' '}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xs-3 col-md-2 col-lg-2'></div>
          </div>
        </div>

        {/* <!-- Technologies Mobile --> */}
        <div className='container-fluid padding d-block d-md-block d-sm-none d-md-none d-lg-none d-xl-none'>
          <div className='row text-center'>
            <div className='col-md-12'>
              <h1 className='dis-6 '>Technologies</h1>
            </div>
          </div>

          <div className='flex-container'>
            <div>
              <img className='img-fluid' src={mongodb} alt='mongodb' />
            </div>
            <div>
              <img className='img-fluid' src={react} alt='react' />
            </div>
          </div>
          <br />
          <br />
          <div className='flex-container'>
            <div>
              <img className='img-fluid' src={expressjs} alt='expressjs' />
            </div>
            <div>
              <img className='img-fluid' src={nodejs} alt='nodejs' />
            </div>
          </div>
          <br />
          <br />
          <div className='flex-container'>
            <div>
              <img className='img-fluid' src={asp} alt='asp' />
            </div>
            <div>
              <img className='img-fluid' src={mysql} alt='mysql' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Technologies
