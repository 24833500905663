import React, { Component } from "react";
import Aboutus from "./Aboutus";


class index extends Component {
  render() {
    return (
      <Aboutus />
    );
  }
}

export default index;
