import React, { Component } from 'react'
import about from '../../resources/img/technologies/about.png'

class Aboutus extends Component {
  render() {
    return (
      <div
        className='container-fluid'
        id='aboutus'
        style={{ backgroundColor: 'hsla(0,0%,85.7%,.1)' }}
      >
        <div
          className='row'
          style={{ marginTop: '10', paddingTop: '5rem', paddingBottom: '5rem' }}
        >
          <div className='col-md-1 col-lg-2'></div>
          <div className='col-md-7 col-lg-4'>
            <h1 className='dis-5 d-none d-sm-none d-md-block d-lg-block d-xl-block'>
              Our Professional
            </h1>
            <h1 className='dis-6 d-block d-sm-block d-md-none d-lg-none d-xl-none'>
              Our Professional
            </h1>
            <br />

            <h6 className='aboutus'>
              We provide solutions for Website Development, Designing, Security,
              software development, Mobile app development and more.Targeted
              towards the organizations who want customised digital solutions.
              We believe in creativity and innovation resulting various design
              thinking ideas for simplified solutions.
            </h6>
          </div>
          <div className='col-md-3 col-lg-4'>
            <img className='img-fluid' src={about} alt='about' />
          </div>
          <div className='col-md-1 col-lg-2'></div>
        </div>
      </div>
    )
  }
}

export default Aboutus
