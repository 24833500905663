import React, { Component } from "react";
import Technologies from "./Technologies";


class index extends Component {
  render() {
    return (
      <Technologies />
    );
  }
}

export default index;
