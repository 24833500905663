import React, { Component } from 'react'
import axios from 'axios'

import contact from '../../resources/img/contact.png'
import business from '../../resources/img/business.png'

import { apiUrl } from '../../config'

const apiURL = apiUrl

class Talkwithus extends Component {
  state = {
    name: '',
    email: '',
    message: '',
    errors: '',
  }

  componentDidMount() {
    // axios.get(`${apiURL}/api/users/arttdetails`).then(response => {
    //     console.log(response);
    //   });
  }

  onChange = (e) => {
    //console.log('_________________',e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSubmit = (e) => {
    e.preventDefault()

    const user = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    }

    console.log('+-+-+-************-+-+-+', user)

    axios
      .post(`${apiURL}/api/users/register`, user)
      .then((response) => {
        console.log('--------response--------', response)
        alert(`Thank you, ${response.data.msg}`)
      })
      // .then(alert(`Thank you`))
      .then(
        this.setState({
          name: '',
          email: '',
          message: '',
        })
      )
  }

  render() {
    return (
      <div>
        {/* <!-- Contact Us --> */}
        <div
          className='container-fluid padding'
          style={{ marginTop: 70, backgroundColor: '#fff' }}
          id='contactus'
        >
          <div className='row' style={{ marginLeft: -2 + 'rem' }}>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <img src={contact} className='img-fluid' alt='contact' />

              <div
                className='card contactform'
                id='cform'
                style={{ width: '60%' }}
              >
                <div className='card-body'>
                  {/* <!-- Material input --> */}
                  <form onSubmit={this.onSubmit.bind(this)}>
                    <div className='group'>
                      <input
                        style={{ width: 100 + '%' }}
                        type='text'
                        name='name'
                        value={this.state.name}
                        onChange={this.onChange.bind(this)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Name</label>
                    </div>

                    <div className='group'>
                      <input
                        style={{ width: 100 + '%' }}
                        type='email'
                        name='email'
                        value={this.state.email}
                        onChange={this.onChange.bind(this)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Email</label>
                    </div>

                    <div className='group'>
                      <input
                        style={{ width: 100 + '%' }}
                        type='text'
                        rows='3'
                        name='message'
                        value={this.state.message}
                        onChange={this.onChange.bind(this)}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label>Message</label>
                    </div>
                    <button
                      type='submit'
                      className='btn btn-primary btn-lg btn-block'
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <div
                id='contact'
                className='d-none d-sm-none d-md-block d-lg-block d-xl-block'
              >
                <img
                  style={{ width: 70 + '%' }}
                  src={business}
                  className='img-fluid'
                  alt='business'
                />
              </div>

              <div
                id='contact'
                className='d-block d-sm-block d-md-none d-lg-none d-xl-none'
              >
                <img
                  style={{
                    width: 70 + '%',
                    height: 70 + '%',
                    marginTop: 12 + 'rem',
                  }}
                  src={business}
                  className='img-fluid'
                  alt='business'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Talkwithus
